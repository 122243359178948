.chatPage{
    background-color: rgb(31, 29, 29);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
}

.chatContainer{
    background-color: rgb(41, 41, 41);
    height: 60%;
    width: 50%;
    border: solid rgb(41, 41, 41);
    border-radius: 3px;
}

.header{
    background-color: crimson;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
}

.header>h2{
    font-size: 1.5vmax;
    color: white;
    margin: 2.5vmax;
}

.header img{
    
    margin: 2.5vmax;
    transition: all 0.3s;
    cursor: pointer;
}
.header>img:hover{
    transform: scale(1.2);
}


.chatBox{

 height: 70%;
 box-sizing: border-box;
 
}

.inputBox{
    
    height: 15%;
    box-sizing: border-box;
    display: flex;
}

#chatInput{
   
    width: 80%;
    border: none;
    padding: 2vmax;
    box-sizing: border-box;
    background-color: rgb(109, 109, 109);
    outline: none;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.4vmax;
    font-weight: 50;
    color: white;
    border: 0.2vmax solid rgba(41, 41, 41, 0.562);
    border-radius: 3px;
}


.sendBtn{
    background-color: crimson;
    color: whitesmoke;
    border: none;
    outline: none;
    width: 21%;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 3px;
}

.sendBtn>img{
    width: 2.6vmax;
    filter:brightness(100) invert();
    transition: all 0.3s;
}
.sendBtn:hover{
    background-color: rgb(156, 13, 42);
} 
.sendBtn:hover img{
    transform: translateX(10px);
}

#closeIcon{
    width: 0.85vmax;
}
#closeIcon:hover{
    transform: translateX(-2px) translateY(2px);
    transition: 0.3s;
}