.messageBox{
    padding: 1vmax;
    margin: 1vmax;
    border-radius: 0.5vmax;
    display: inline-block;
    clear: both;
    font-size: 1.4vmax;
    font-weight: 50;
    font-family: 'Courier New', Courier, monospace;
}

.right{
    float: right;
    background-color: crimson;
    color: white;
    text-align: right;
}

.left{
    float: left;
    text-align: left;
    background-color: rgb(196, 255, 184);
    
}

.containerCentre
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3vmax;
    font-size: 1.3vmax;
    border-radius: 5px;
    background-color: rgb(207, 207, 207);
    box-sizing: border-box;
    width: 50%;
    margin: auto;
    margin-top: 1vmax;
    clear: both;
}
.centered
{
    text-align: center;
}